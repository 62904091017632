import React from 'react';

import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ListEngagement from '../../../components/ContentType/ListEngagement/ListEngagement';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import { getImage, getBreadCrumb } from '../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const Commitment = ({ data }) => {
  const imagesArray = data.allImages.edges;
  const rse = data.rse;
  const rseBlock = rse?.relationships?.field_blocs?.find(x => x.__typename === 'block_content__block_engagement');
  const rseTitle = rseBlock.field_offre_text;
  const rseList = rseBlock.field_bloc_text_1.map(c => c.processed);
  const commitments = rse?.relationships?.field_blocs?.filter(x => x.__typename === 'block_content__basic');
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, rse.path?.alias);

  return (
    <Layout>
      <Seo
        title={rse.title}
        description="Description de la page Colas History"
      />

      <div className={classNames('page_template', 'page_engagements')}>
        <PageBanner visuel={getImage(imagesArray, rse?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                locale: rse.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={rse.title}
              description={rse.field_sous_titre}
            />
          </div>
        </PageBanner>
        <ListEngagement title={rseTitle} commitments={rseList} customClass="block_0_section" />
        <div className="left_right_sections">
          <div className="wrapper_page">
            {commitments?.map((block, i) => {
              return (
                <section key={i} className={classNames('section_content', 'block_' + (i + 1) + '_section')}>
                  <TextVisuelCTATwoCol
                    extremeLeft={(block?.field_no_padding && block?.field_alignment === 'left') ? true : false}
                    extremeRight={(block?.field_no_padding && block?.field_alignment === 'left') ? false : true}
                    orderInverse={block?.field_alignment === 'right' ? true : false}
                    alignLeft={block?.field_alignment === 'left' ? true : false}
                    alignRight={block?.field_alignment === 'right' ? true : false}
                    visuel={getImage(imagesArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                    visuelSize='half'
                    alignTop={true}
                    title={{
                      titleSize: 'h2',
                      titleText: block.field_title.processed,
                      subtitle: block.field_subtitle.processed
                    }}
                    text={block.body.processed}
                    cta={{
                      ctaText: block.field_link?.title,
                      ctaLink: block.field_link?.url
                    }}
                  />
                </section>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    rse: nodePage(langcode: {eq: $locale}, path: {alias: {eq: $slug}}) {
      title
      field_sous_titre
      langcode
      path {
        alias
        langcode
      }
      relationships {
        field_image {
          drupal_internal__mid
        }
        field_mobile_image {
          drupal_internal__mid
        }
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__basic {
              relationships {
                field_image {
                  drupal_internal__mid
                }
              }
              field_title {
                processed
              }
              field_title_type
              field_subtitle {
                processed
              }
              body {
                processed
              }
              field_alignment
              field_no_padding
              field_link {
                title
                url
              }
            }
            ... on block_content__block_engagement {
              field_offre_text
              field_bloc_text_1 {
                processed
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"}, 
        drupal_internal__mid: {in: $imagesID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }

    allMenu :   allMenuLinkContentMenuLinkContent(
      filter: {langcode: {eq: $locale}}
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

Commitment.propTypes = {
  data: PropTypes.object.isRequired
};

export default Commitment;
