import React from 'react';

import { PropTypes } from 'prop-types';

import { RoundedArrow } from '../../../Atoms/Icons/Icons';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import { getOffset } from '../../../Utils/Utils';

import './ListEngagement.scss';

let classNames = require('classnames');

const ListEngagement = ({ title, commitments, customClass }) => {
  const stringToHTML = (text) => {
    return { __html: text };
  };

  const onClickItem = (e) => {
    e.preventDefault();
    const target = e.currentTarget.href.split('#')[1];
    const target_section = document.querySelectorAll('.left_right_sections .section_content')[parseInt(target, 10)]
    if (target_section) {
      window.scrollTo({ top: getOffset(target_section).top - 100, behavior: 'smooth' });
    }
  }

  return (
    <section className={classNames('section_content', 'list_engagement_component', customClass || '')}>
      <div className="wrapper_page">
        <GraphRH text={title} jobs={commitments.length} textColor="color_dark_bleu" />
        <div className="col">
          {commitments.map((commitment, i) => (
            <a href={`#${i}`} className="col_item" key={i} onClick={onClickItem}>
              <RoundedArrow color="color_dark_bleu" />
              <div dangerouslySetInnerHTML={stringToHTML(commitment)} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

ListEngagement.propTypes = {
  title: PropTypes.string.isRequired,
  commitments: PropTypes.array.isRequired,
  customClass: PropTypes.string
};

export default ListEngagement;
